import {
  TopNavContainer,
  TopNavFullContainer,
} from '../../asset/styles/mobile/topnav/Topnav';

import { useNavigate, useLocation, Link } from 'react-router-dom';
import close from './../../asset/images/close/close_black.svg';
import LogoKor from '../../asset/images/new/Logo(korean).svg';
import LogoEng from '../../asset/images/new/Logo(english).svg';
import CloseIcon from '../../asset/images/new/Close.svg';
import Profile from '../../asset/images/new/profile.svg';
import Hamburger from '../../asset/images/new/hamberger.svg';
import mypage from './../../asset/images/topnav/mypage.svg';
import Arrow from './../../asset/images/topnav/arrowBack.svg';
import logoImg from './../../asset/images/logo/logo.svg';
import { useAuth } from '../../utilities/auth';
import Sidemenu from './Hamburger';
import BackArrowImg from '../../asset/images/topnav/arrowBack.svg';
import { isMobile } from '../function/function';
import whiteLogo from './../../asset/images/new/whiteLogo.svg';
import whiteKorLogo from './../../asset/images/new/whiteKorLogo.svg';
import whiteMypage from './../../asset/images/new/whiteMypage.svg';
import whiteHamburger from './../../asset/images/new/whiteHamburger.svg';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SetPopUpOn } from '../../store/onOffSlice';
import Mypage from '../../asset/images/new/mypage.svg';
import GetActService from '../../service/act/Act';
import SignController from '../../controller/sign/SignController';
import UserService from '../../service/checkAccount/CheckAccount';
import { PointPopUp } from '../../pages/point/Popup';
interface TopNav2PropsType {
  text: string;
  close?: boolean;
  back?: boolean;
  browser?: boolean;
  closeBack?: boolean;
  setClose?: any;
  hamburger?: boolean;
  onClick?: any;
  report?: any;
  search?: any;
  mypage?: any;
  setCloseSearchPointDetail?: any;
}

export const Topnav = ({ text, setOpen, open, home }: any) => {
  const { isLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className='h-48 padding-x-16 flex-row space-between center-y bg-FFFFFF w-100per fixed top-0 border-b-171E26 z-19 center-y tablet center-y'>
      <Sidemenu setOpen={setOpen} open={open} />
      {!text ? (
        <img
          className=' hover'
          src={LogoKor}
          alt=''
          onClick={() => {
            navigate('/');
          }}
        />
      ) : (
        <img
          className=' hover'
          src={Arrow}
          alt=''
          onClick={() => {
            if (location.state?.from === '/login' || home) {
              navigate('/');
            } else if (
              (location.pathname.slice(-1) === '/'
                ? location.pathname.slice(0, -1)
                : location.pathname) === '/aiFarming/map'
            ) {
              navigate('aiFarming/');
            } else {
              navigate(-1);
            }
          }}
        />
      )}
      <div className='font-medium f-size-16 line-h-24'>{text}</div>

      {!text ? (
        <div className='flex-row center-y gap-16 center-y'>
          {isLogin() ? (
            <img
              className=' hover'
              src={mypage}
              alt=''
              onClick={() => {
                navigate('/mypage');
              }}
            />
          ) : (
            <img
              className=' hover'
              src={Profile}
              alt=''
              onClick={() => {
                navigate('/login');
              }}
            />
          )}
          <img
            src={Hamburger}
            alt=''
            className='pointer hover'
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
      ) : (
        <div className='padding-x-10'></div>
      )}
    </div>
  );
};

export const TopNav2 = ({
  text,
  close,
  back,
  browser,
  closeBack,
  setClose,
  hamburger,
  onClick,
  mypage,
  search,
}: TopNav2PropsType) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const backArrow = () => {
    return (
      <img
        src='/asset/images/arrow/backArrowTopNav.svg'
        alt='backarrow'
        style={{ cursor: 'point' }}
        className=' hover'
        onClick={() => {
          // if (onClick && typeof onClick === "function") {
          //   onClick();
          // }
          // alert(JSON.stringify(location))
          if (location.state?.from === '/login') {
            navigate('/');
          } else if (
            (location.pathname.slice(-1) === '/'
              ? location.pathname.slice(0, -1)
              : location.pathname) === '/aiFarming/map'
          ) {
            navigate('aiFarming/');
          } else if (
            (location.pathname.slice(-1) === '/'
              ? location.pathname.slice(0, -1)
              : location.pathname) === '/point/recharge/payment'
          ) {
            onClick();
          } else {
            navigate(-1);
          }
        }}
      />
    );
  };

  return (
    <div
      className={`flex-column w-100per center-y relative ${
        browser ? '' : 'tablet'
      } z-20 bg-FFFFFF`}
    >
      <TopNavFullContainer>
        <div className='w-100per padding-12-16 flex-row space-between'>
          <Sidemenu setOpen={setOpen} open={open} />
          {!close ? (
            backArrow()
          ) : back ? (
            backArrow()
          ) : (
            <div className='w-24'></div>
          )}
          <div className='font-bold f-size-16 line-h-24 color-text-primary'>
            {text}
          </div>
          {close ? (
            <img
              src={CloseIcon}
              alt=''
              className='w-24 hover'
              onClick={() =>
                closeBack
                  ? isMobile(window.innerWidth)
                    ? mypage && location.pathname === '/mypage/point'
                      ? setClose(false)
                      : navigate(-1)
                    : setClose(false)
                  : navigate('/')
              }
            />
          ) : hamburger ? (
            <img
              src='/asset/images/main/hamburger_black.svg'
              onClick={() => {
                setOpen(!open);
              }}
            />
          ) : (
            <div></div>
          )}
        </div>
      </TopNavFullContainer>
    </div>
  );
};

export const TopNavBack = ({ text, back }: TopNav2PropsType) => {
  const navigate = useNavigate();
  return (
    <div className='flex-column w-100per center-y tablet z-10 bg-FFFFFF h-48 border-b-171E26'>
      <div className='w-100per flex-row h-48 padding-12-16 center-y '>
        <div className='w-33per start-x'>
          <img
            alt='arrowBack'
            src='/asset/images/arrow/backArrowTopNav.svg'
            className='w-14 h-8'
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>

        <div className='font-medium f-size-16 line-h-24 color-text-primary w-33per no-wrap text-center'>
          {text}
        </div>
        <div className='w-33per'>
          <div>&nbsp;</div>
        </div>
        <div />
      </div>
    </div>
  );
};
export const TopNavTerms = () => {
  const navigate = useNavigate();
  return (
    <div className='flex-column w-100per center-y tablet z-10 bg-FFFFFF border-171E26 padding-b-10'>
      <div className='w-100per flex-row padding-12-16 center-y '>
        <div className='w-33per'>
          <img src={logoImg} className='hover w-20' />
        </div>
        <div className='font-bold f-size-25 line-h-24 color-text-primary w-33per no-wrap text-center'>
          회원가입
        </div>
        <div className='w-33per end-x'>
          <img
            src={close}
            alt=''
            className='w-20 hover'
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div />
      </div>
    </div>
  );
};

export const TopNavNew = ({ setOpen, open }: any) => {
  const navigate = useNavigate();
  const { isLogin } = useAuth();

  return (
    <div className='padding-16 bg-primary-500 tablet w-100per center-y'>
      <Sidemenu setOpen={setOpen} open={open} />

      <div className='flex-row space-between w-100per center-y'>
        {/* <img
            src={whiteMypage}
            className="pointer"
            onClick={() => {
              isLogin() ? (() => {})() : navigate("/login");
            }}
          /> */}
        <div
          className='flex-row gap-5 pointer center-y pointer'
          onClick={() => {
            navigate('/');
          }}
        >
          <img src={whiteLogo} />
          <img src={whiteKorLogo} />
        </div>
        <img
          src={whiteHamburger}
          className='pointer'
          onClick={() => {
            setOpen(!open);
          }}
        />
      </div>
    </div>
  );
};

export const TopNavNewBrowser = ({ showMenu = true }) => {
  const [hover, setHover] = useState(false);
  const [hoverText1, setHoverText1] = useState(false);
  const [hoverText2, setHoverText2] = useState(false);
  const [hoverText3, setHoverText3] = useState(false);
  const [hoverText4, setHoverText4] = useState(false);
  const [hoverText5, setHoverText5] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoverText6, setHoverText6] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isLogin, onLogout } = useAuth();

  const LogoutApi = () => SignController.LogoutApi(onLogout);
  const dummy = [
    { name: '가격 분석', url: '/price/map', tab: 'price', idx: '1' },
    { name: '매물 분석', url: '/auction/map', tab: 'auction', idx: '2' },
    { name: '농지연금 분석', url: '/annuity/map', tab: 'annuity', idx: '3' },
    { name: '농지 찾기', url: '/findland/map', tab: 'findland', idx: '4' },
    { name: '내 농장 진단', url: '/aiFarming/map', tab: 'aifarm', idx: '5' },
    { name: '내 집 짓기', url: '/house/map', tab: 'house', idx: '6' },
    {
      name: '숨은 정책 지원금',
      url: '/hidden/policy',
      tab: 'policy',
      idx: '7',
    },
  ];
  const [point, setPoint] = useState(0);
  const [freePoint, setFreePoint] = useState(0);
  const CheckPoint = async () => {
    if (isLogin()) {
      try {
        const result = await UserService.checkPoint();
        setPoint(result.data.data.paidPoint);
        setFreePoint(result.data.data.freePoint);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    CheckPoint();
  });

  return (
    <div className='flex-column browser w-100per'>
      <div className='border-b-E2E2E2 w-100per center-x'>
        <div
          className='padding-16-60 flex-row space-between browser-max-w-1320 w-100per w-100per'
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <Link to='/'>
            <img src={LogoKor} alt='' className='hover' />
          </Link>

          <div
            className='flex-row gap-16 center-y relative'
            style={{ position: 'relative' }}
          >
            <div
              className='font-medium f-size-14 line-h-20 color-text-secondary pointer hover'
              onClick={() => navigate('/notice')}
            >
              <Link
                to='/notice'
                style={{
                  textDecoration: 'none',
                  color: 'rgba(23, 30, 38, 0.78)',
                }}
              >
                공지사항
              </Link>
            </div>

            <div
              className='absolute w-10 h-10 flex-center bg-increase border-radius-999'
              style={{ top: '0px', left: `45px` }}
            >
              <div className='font-bold f-size-8 color-FFFFFF'>N</div>
            </div>

            {isLogin() ? (
              <img
                className='pointer'
                src={Mypage}
                alt=''
                onMouseEnter={() => {
                  setHover(true);
                }}
              />
            ) : (
              <div
                className='font-medium f-size-14 line-h-20 color-text-secondary pointer hover'
                onClick={() => navigate('/login')}
              >
                <Link
                  to='/login'
                  style={{
                    textDecoration: 'none',
                    color: 'rgba(23, 30, 38, 0.78)',
                  }}
                >
                  로그인
                </Link>
              </div>
            )}
            {isLogin() ? (
              <div className='font-medium -pref-size-14 line-h-20 pointer hover'></div>
            ) : (
              <div className='bg-1E986B border-radius-18 padding-8-16 flex-center'>
                <div className='font-medium f-size-14 line-h-20 pointer hover color-FFFFFF'>
                  <Link
                    to='/signUp'
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    회원가입
                  </Link>
                </div>
              </div>
            )}
            {hover && (
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  right: '-27%',
                  marginTop: '10px',
                  width: '120px',
                  boxSizing: 'border-box',
                  border: '1px solid #171e2610',
                  borderRadius: '8px',
                  zIndex: 100,
                }}
              >
                <div className='border-radius-t-r-8 border-radius-t-l-8 bg-primary-400 flex-column padding-8-16'>
                  <div className='flex-row gap-5 center-y sp nowrap'>
                    <div className='m-c1-12-b color-FFFFFF'>총 포인트</div>
                    <img src='/asset/images/icon/flower.svg' alt='flower' />
                  </div>
                  <div className='m-b2-14-b color-FFFFFF'>
                    {(point + freePoint).toLocaleString()}P
                  </div>
                </div>
                <div className='bg-FFFFFF flex-column border-radius-b-r-8 border-radius-b-l-8 color-text-tertiary'>
                  <div
                    className='flex-column padding-8-16 pointer'
                    onClick={() => {
                      navigate('/point/recharge');
                      sessionStorage.setItem('searchPointRecharge', 'N');
                    }}
                    onMouseEnter={() => {
                      setHoverText2(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText2(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText2 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      충전소
                    </div>
                  </div>
                  <div
                    className='flex-column padding-8-16  pointer'
                    onClick={() => {
                      navigate('/mypage', { state: { tab: 'point' } });
                      sessionStorage.setItem('mypageTab', 'point');
                      window.location.reload();
                    }}
                    onMouseEnter={() => {
                      setHoverText1(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText1(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m no-wrap ${
                        hoverText1 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      포인트 내역
                    </div>
                  </div>

                  <div
                    className='flex-column padding-8-16 pointer bg-FFFFFF border-radius-b-r-8 border-radius-b-l-8'
                    onClick={() => {
                      onLogout();
                    }}
                    onMouseEnter={() => {
                      setHoverText3(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText3(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText3 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      로그아웃
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {showMenu && (
        <div className='border-b-E2E2E2 w-100per center-x'>
          <div className='flex-row gap-48 padding-16-60 browser-1920-gap-80  browser-max-w-1320 w-100per'>
            {dummy.map((item) => {
              return (
                <div
                  key={item.idx}
                  className='font-bold tablet-f-size-16 lien-h-26 color-text-primary pointer browser-f-size-18'
                  onClick={() => {
                    navigate(item.url);
                    sessionStorage.setItem('tab', item.tab);
                    (item.tab === 'price' || item.tab === 'annuity') &&
                      dispatch(SetPopUpOn(true));
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      )} */}
    </div>
  );
};
export const TopNavWithTerms = () => {
  const [hover, setHover] = useState(false);
  const [hoverText1, setHoverText1] = useState(false);
  const [hoverText2, setHoverText2] = useState(false);
  const [hoverText3, setHoverText3] = useState(false);
  const [hoverText4, setHoverText4] = useState(false);
  const [hoverText5, setHoverText5] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isLogin, onLogout } = useAuth();

  const LogoutApi = () => SignController.LogoutApi(onLogout);
  const terms = [
    { name: '이용약관', url: '/terms/service', idx: '1' },
    { name: '개인정보 처리방침', url: '/terms/privacy_policy', idx: '2' },
    { name: '위치기반서비스 이용약관', url: '/terms/location', idx: '3' },
  ];
  const [point, setPoint] = useState(0);
  const CheckPoint = async () => {
    if (isLogin()) {
      try {
        const result = await UserService.checkPoint();
        setPoint(result.data.data.freePoint + result.data.data.paidPoint);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    CheckPoint();
  });

  return (
    <div className='flex-column browser w-100per'>
      <div className='border-b-E2E2E2 w-100per center-x'>
        <div
          className='padding-16-60 flex-row space-between browser-max-w-1320 w-100per w-100per'
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <img
            src={LogoKor}
            className='hover'
            onClick={() => navigate('/')}
            alt=''
          />
          <div
            className='flex-row gap-16 center-y relative'
            style={{ position: 'relative' }}
          >
            {isLogin() ? (
              <img
                className='pointer'
                src={Mypage}
                alt=''
                onMouseEnter={() => {
                  setHover(true);
                }}
              />
            ) : (
              <div
                className='font-medium f-size-14 line-h-20 pointer hover'
                onClick={() => navigate('/login')}
              >
                로그인
              </div>
            )}
            {isLogin() ? (
              <div className='font-medium -pref-size-14 line-h-20 pointer hover'></div>
            ) : (
              <div className='bg-1E986B border-radius-18 padding-8-16 flex-center'>
                <div
                  className='font-medium f-size-14 line-h-20 pointer hover color-FFFFFF'
                  onClick={() => window.location.replace('/signup')}
                >
                  회원가입
                </div>
              </div>
            )}
            {hover && (
              <div
                className={`absolute flex-column border-radius-8 w-216 z-100 border-171E26`}
                style={{
                  top: '100%', // 이미지의 아래에 위치하도록 설정
                  right: '-50%', // 오른쪽 정렬
                  marginTop: '10px',
                }}
              >
                <div className='border-radius-t-r-8 border-radius-t-l-8 bg-primary-400 flex-column padding-8-16'>
                  <div className='flex-row gap-5 center-y sp nowrap'>
                    <div className='m-c1-12-b color-FFFFFF'>보유 포인트</div>
                    <img src='/asset/images/icon/flower.svg' alt='flower' />
                  </div>
                  <div className='m-b2-14-b color-FFFFFF'>
                    {point.toLocaleString()}P
                  </div>
                </div>
                <div className='bg-FFFFFF flex-column border-radius-b-r-8 border-radius-b-l-8'>
                  <div
                    className='flex-column padding-8-16  pointer'
                    onClick={() => {
                      navigate('/mypage', { state: { tab: 'point' } });
                      sessionStorage.setItem('mypageTab', 'point');
                      window.location.reload();
                    }}
                    onMouseEnter={() => {
                      setHoverText1(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText1(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m no-wrap ${
                        hoverText1 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      포인트 내역
                    </div>
                  </div>
                  <div
                    className='flex-column padding-8-16  pointer'
                    onClick={() => {
                      navigate('/mypage', { state: { tab: 'refundPolicy' } });
                      sessionStorage.setItem('mypageTab', 'refundPolicy');
                      window.location.reload();
                    }}
                    onMouseEnter={() => {
                      setHoverText4(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText4(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText4 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      환불 안내
                    </div>
                  </div>
                  <div
                    className='flex-column padding-8-16  pointer'
                    onClick={() => {
                      navigate('/mypage', { state: { tab: 'notice' } });
                      sessionStorage.setItem('mypageTab', 'notice');
                      window.location.reload();
                    }}
                    onMouseEnter={() => {
                      setHoverText5(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText5(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText5 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      공지사항
                    </div>
                  </div>
                  <div
                    className='flex-column padding-8-16 pointer'
                    onClick={() => {
                      navigate('/point/recharge');
                      sessionStorage.setItem('searchPointRecharge', 'N');
                    }}
                    onMouseEnter={() => {
                      setHoverText2(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText2(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText2 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      충전소
                    </div>
                  </div>
                  <div
                    className='flex-column padding-8-16 pointer bg-FFFFFF border-radius-b-r-8 border-radius-b-l-8'
                    onClick={() => {
                      onLogout();
                    }}
                    onMouseEnter={() => {
                      setHoverText3(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText3(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText3 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      로그아웃
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='border-b-E2E2E2 w-100per center-x'>
        <div className='flex-row gap-48 padding-16-60 browser-1920-gap-80  browser-max-w-1320 w-100per'>
          {terms.map((item) => {
            return (
              <div
                key={item.idx}
                className='font-bold tablet-f-size-16 lien-h-26 color-text-primary pointer browser-f-size-18'
                onClick={() => {
                  navigate(item.url);
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export const TopNavNewBrowserWithHamburger = () => {
  const [hover, setHover] = useState(false);
  const [hoverText1, setHoverText1] = useState(false);
  const [hoverText2, setHoverText2] = useState(false);
  const [hoverText3, setHoverText3] = useState(false);
  const [hoverText4, setHoverText4] = useState(false);
  const [hoverText5, setHoverText5] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isLogin, onLogout } = useAuth();
  const LogoutApi = () => SignController.LogoutApi(onLogout);
  const dummy = [
    { name: '가격 분석', url: '/price/map', tab: 'price', idx: '1' },
    { name: '매물 분석', url: '/auction/map', tab: 'auction', idx: '2' },
    { name: '농지연금 분석', url: '/annuity/map', tab: 'annuity', idx: '3' },
    { name: '농지 찾기', url: '/findland/map', tab: 'findland', idx: '4' },
    { name: '내 농장 진단', url: '/aiFarming/map', tab: 'aifarm', idx: '5' },
    { name: '내 집 짓기', url: '/house/map', tab: 'house', idx: '6' },
    {
      name: '숨은 정책 지원금',
      url: '/hidden/policy',
      tab: 'policy',
      idx: '7',
    },
  ];
  const [point, setPoint] = useState(0);
  const CheckPoint = async () => {
    if (isLogin()) {
      try {
        const result = await UserService.checkPoint();
        setPoint(result.data.data.freePoint + result.data.data.paidPoint);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    CheckPoint();
  });

  return (
    <div className='flex-column browser w-100per'>
      <div className='border-b-E2E2E2 w-100per center-x'>
        <div className='padding-16-60 flex-row space-between browser-max-w-1320 w-100per w-100per'>
          <img
            src={LogoKor}
            className='hover'
            onClick={() => navigate('/')}
            alt=''
          />
          <div
            className='flex-row gap-16 center-y relative'
            onMouseLeave={() => {
              setHover(false);
            }}
          >
            {isLogin() ? (
              <img
                className='pointer'
                src={Mypage}
                alt=''
                onMouseEnter={() => {
                  setHover(true);
                }}
              />
            ) : (
              <div
                className='font-medium f-size-14 line-h-20 pointer hover'
                onClick={() => navigate('/login')}
              >
                로그인
              </div>
            )}

            {hover && (
              <div
                className='fixed flex-column border-radius-8 w-122 z-100 border-171E26'
                style={{ top: '45px' }}
              >
                <div className='border-radius-t-r-8 border-radius-t-l-8 bg-primary-400 flex-column padding-8-16'>
                  <div className='flex-row gap-5 center-y'>
                    <div className='m-c1-12-b color-FFFFFF'>보유 포인트</div>
                    <img src='/asset/images/icon/flower.svg' alt='flower' />
                  </div>
                  <div className='m-b2-14-b color-FFFFFF'>
                    {point.toLocaleString()}P
                  </div>
                </div>
                <div className='bg-FFFFFF flex-column border-radius-b-r-8 border-radius-b-l-8'>
                  <div
                    className='flex-column padding-8-16  pointer'
                    onClick={() => {
                      navigate('/mypage', { state: { tab: 'point' } });
                      sessionStorage.setItem('mypageTab', 'point');
                      window.location.reload();
                    }}
                    onMouseEnter={() => {
                      setHoverText1(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText1(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m no-wrap ${
                        hoverText1 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      포인트 내역
                    </div>
                  </div>
                  <div
                    className='flex-column padding-8-16  pointer'
                    onClick={() => {
                      navigate('/mypage', { state: { tab: 'refundPolicy' } });
                      sessionStorage.setItem('mypageTab', 'refundPolicy');
                      window.location.reload();
                    }}
                    onMouseEnter={() => {
                      setHoverText4(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText4(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText4 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      환불 안내
                    </div>
                  </div>
                  <div
                    className='flex-column padding-8-16  pointer'
                    onClick={() => {
                      navigate('/mypage', { state: { tab: 'notice' } });
                      sessionStorage.setItem('mypageTab', 'notice');
                      window.location.reload();
                    }}
                    onMouseEnter={() => {
                      setHoverText5(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText5(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText5 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      공지사항
                    </div>
                  </div>
                  <div
                    className='flex-column padding-8-16 pointer'
                    onClick={() => {
                      navigate('/point/recharge');
                      sessionStorage.setItem('searchPointRecharge', 'N');
                    }}
                    onMouseEnter={() => {
                      setHoverText2(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText2(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText2 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      충전소
                    </div>
                  </div>
                  <div
                    className='flex-column padding-8-16 pointer bg-FFFFFF border-radius-b-r-8 border-radius-b-l-8'
                    onClick={() => {
                      onLogout();
                    }}
                    onMouseEnter={() => {
                      setHoverText3(true);
                    }}
                    onMouseLeave={() => {
                      setHoverText3(false);
                    }}
                  >
                    <div
                      className={`w-c1-14-m ${
                        hoverText3 ? `color-primary-300` : `color-tertiary`
                      }`}
                    >
                      로그아웃
                    </div>
                  </div>
                </div>
              </div>
            )}
            <img
              className='pointer'
              src='/asset/images/main/hamburger_black.svg'
              onClick={() => {
                setOpen(!open);
              }}
              alt=''
            />
          </div>
        </div>
      </div>
      {open && (
        <div className='border-b-E2E2E2 w-100per center-x'>
          <div className='flex-row gap-48 padding-16-60 browser-1920-gap-80  browser-max-w-1320 w-100per'>
            {dummy.map((item) => {
              return (
                <div
                  key={item.idx}
                  className='font-bold tablet-f-size-16 lien-h-26 color-text-primary pointer browser-f-size-18'
                  onClick={() => {
                    navigate(item.url);
                    sessionStorage.setItem('tab', item.tab);
                    (item.tab === 'price' || item.tab === 'annuity') &&
                      dispatch(SetPopUpOn(true));
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export const TopNavPointBrowser = ({
  close,
  back,
  text,
  search,
  type,
}: any) => {
  const navigate = useNavigate();

  const [onPopUp, setOnPopUp] = useState(false);
  return (
    <div className='flex-row space-between padding-8-40 border-b-171E26 browser'>
      {onPopUp && (
        <PointPopUp setOpen={setOnPopUp} open={onPopUp} type='payment' />
      )}
      <div className='w-33per'>
        {back && (
          <img
            src='/asset/images/arrow/ArrowLeftPage.svg'
            className='pointer'
            onClick={() => {
              // navigate(-1);
              setOnPopUp(true);
            }}
          />
        )}
      </div>
      <div className='w-33per flex-center mobile-title-2-bold color-dark-primary'>
        {text}
      </div>
      <div className='w-33per end-x'>
        {close && (
          <img
            src='/asset/images/close/CloseBlack24.svg'
            className='pointer'
            onClick={() => {
              // if (search) {
              //   navigate(-1);
              // } else {
              //   navigate("/");
              // }
              if (type === 'payment') {
                setOnPopUp(true);
              } else {
                if (search) {
                  navigate(-1);
                } else {
                  navigate('/');
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
