import { useNavigate } from 'react-router-dom';
import SearchReportMain from '../../reportResultSearch/Main';
import HouseReportMain from '../../reportResultHouse/Main';
import { useEffect } from 'react';
import PensionReportMain from '../../reportResultPension/Main';
import PriceReportMain from '../../reportResultPrice/Main';
import FarmingReportMain from '../../aiFarming/report/Main';
import AuctionReportMain from '../../reportResultSale/Main';

export const ReportDetail = ({
  reportIdx,
  setClose,
  type,
  auctionType,
  open,
  address,
  des,
}: any) => {
  useEffect(() => {
    // 모달이 열릴 때 스크롤 막기
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    // 모달이 닫힐 때 스크롤 해제
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);
  const navigate = useNavigate();

  return (
    <div
      className='flex-column w-100per h-100per flex-center '
      style={{
        backgroundColor: '#00000060',
        position: 'fixed',
        zIndex: 999,
        overflowX: 'hidden',
        overflowY: 'hidden',
      }}
    >
      <div
        className='bg-FFFFFF relative'
        // style={{ width: '100%', height: '100%' }}
        style={{ width: '365px', height: '80%' }}
      >
        {type === '농지 찾기' ? (
          <SearchReportMain mypage reportIdx={reportIdx} setClose={setClose} />
        ) : type === '내 집 짓기' ? (
          <HouseReportMain mypage reportIdx={reportIdx} setClose={setClose} />
        ) : type === '농지연금 분석' ? (
          <PensionReportMain mypage reportIdx={reportIdx} setClose={setClose} />
        ) : type === '가격분석' ? (
          <PriceReportMain
            mypage
            reportIdx={reportIdx}
            setClose={setClose}
            address2={address}
            des={des}
          />
        ) : type === '내 농장 진단' ? (
          <FarmingReportMain mypage reportIdx={reportIdx} setClose={setClose} />
        ) : type === '매물 분석' ? (
          <AuctionReportMain
            mypage
            reportIdx={reportIdx}
            setClose={setClose}
            auctionType={auctionType}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
