import { useEffect, useRef, useState } from 'react';
import {
  ComposedChart,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
} from 'recharts';

const Main4 = ({
  houseIndexGraph,
  newConstructionIndexGraph,
  remodelingIndexGraph,
  wholesaleGraph,
  jointMarketGraph,
  farmPriceGraph,
}: any) => {
  const temp = useRef<HTMLDivElement>(null);
  const [pyeong, setPyeong] = useState(false);

  const CustomTooltip = ({ active, payload, label, type }: any) => {
    if (active && payload && payload.length > 1) {
      // Ensure payload exists and has at least two items
      const formattedAmount = new Intl.NumberFormat('en-US').format(
        payload[0]?.value ?? 0
      ); // Safe access to payload[0].value
      const formattedAmount2 = new Intl.NumberFormat('en-US').format(
        payload[1]?.value ?? 0
      ); // Safe access to payload[1].value
      const year = label && label.split('-')[0];
      const month = label && label.split('-')[1];
      const day = label && label.split('-')[2];

      return (
        <div className='border-radius-8 flex-column bg-FFFFFF padding-16 gap-10 border-171E26'>
          <p className='m-c2-11-m'>
            {type === 'wholesale' || type === 'jointMarket'
              ? `${year}년 ${month}월 ${day}일`
              : `${year}년 ${month}월`}
          </p>
          <p className='m-c2-11-m'>{`거래량 : ${formattedAmount} ${
            type === 'wholesale' || type === 'jointMarket' ? 'kg' : ''
          }`}</p>
          <p className='m-c2-11-m color-primary-300'>{`지수 : ${formattedAmount2}`}</p>
        </div>
      );
    }

    return null;
  };

  const dummy = [
    {
      idx: '1',
      name: '월별 농지가격 지수',
      data: farmPriceGraph,
      type: 'landPrice',
      title: (() => {
        let a = farmPriceGraph[farmPriceGraph.length - 1] || {
          transactionNumber: 0,
          index: 0,
          stdMonth: '01',
          stdYear: '2023',
        };
        let b = farmPriceGraph[farmPriceGraph.length - 2] || {
          transactionNumber: 0,
          index: 0,
          stdMonth: '01',
          stdYear: '2023',
        };

        let x = Math.round((a.index - b.index) * 10) / 10;

        return (
          <div className='font-medium f-size-14 line-h-16 padding-x-16'>
            <span className='f-size-16 line-h-24 font-bold text-color-secondary'>
              {Number(Math.round(a.index * 10) / 10).toLocaleString()}
            </span>{' '}
            ({' '}
            <span
              style={{
                color: x < 0 ? 'blue' : 'red',
              }}
            >
              {x > 0 ? '+' : x < 0 ? '' : ''}
              {x}p
            </span>
            ,{' '}
            {a.stdMonth.split('-')[0] +
              '년' +
              ' ' +
              a.stdMonth.split('-')[1] +
              '월' +
              ' '}
            현재 )
          </div>
        );
      })(),
    },
    //98.7 (-2.2p, 2023.09 기준)
    {
      idx: '2',
      name: '월별 단독주택 가격 지수',
      data: houseIndexGraph,
      type: 'housePrice',
      title: (() => {
        let a = houseIndexGraph[houseIndexGraph.length - 1] || {
          stdYear: '2023',
          stdMonth: '09',
          index: 98.7,
        };
        let b = houseIndexGraph[houseIndexGraph.length - 2] || {
          stdYear: '2023',
          stdMonth: '09',
          index: 98.7,
        };
        let x = Math.round((a.index - b.index) * 10) / 10;
        return (
          <div className='font-medium f-size-14 line-h-16 padding-x-16'>
            <span className='f-size-16 line-h-24 font-bold text-color-secondary'>
              {Number(Math.round(a.index * 10) / 10).toLocaleString()}
            </span>{' '}
            ({' '}
            <span
              style={{
                color: x < 0 ? 'blue' : 'red',
              }}
            >
              {/* {x > 0 ? "+" : x < 0 ? "" : ""} */}
              {x}p
            </span>
            ,{' '}
            {a.stdMonth.split('-')[0] +
              '년' +
              ' ' +
              a.stdMonth.split('-')[1] +
              '월'}{' '}
            현재 )
          </div>
        );
      })(),
    },
    {
      idx: '3',
      name: '월별 단독주택 신축 개발 지수',
      data: newConstructionIndexGraph,
      type: 'new',
      title: (() => {
        let a = newConstructionIndexGraph[
          newConstructionIndexGraph.length - 1
        ] || {
          stdYear: '2023',
          stdMonth: '09',
          index: 98.7,
        };
        let b = newConstructionIndexGraph[
          newConstructionIndexGraph.length - 2
        ] || {
          stdYear: '2023',
          stdMonth: '09',
          index: 98.7,
        };
        let x = Math.round((a.index - b.index) * 10) / 10;
        return (
          <div className='font-medium f-size-14 line-h-16 padding-x-16'>
            <span className='f-size-16 line-h-24 font-bold text-color-secondary'>
              {Number(Math.round(a.index * 10) / 10).toLocaleString()}
            </span>{' '}
            ({' '}
            <span
              style={{
                color: x < 0 ? 'blue' : 'red',
              }}
            >
              {x > 0 ? '+' : x < 0 ? '' : ''}
              {x}p
            </span>
            ,{' '}
            {a.stdMonth.split('-')[0] +
              '년' +
              ' ' +
              a.stdMonth.split('-')[1] +
              '월'}{' '}
            현재 )
          </div>
        );
      })(),
    },
    {
      idx: '4',
      name: '월별 단독주택 리모델링 개발 지수',
      data: remodelingIndexGraph,
      type: 'remodel',
      title: (() => {
        let a = remodelingIndexGraph[remodelingIndexGraph.length - 1] || {
          stdYear: '2023',
          stdMonth: '09',
          index: 98.7,
        };
        let b = remodelingIndexGraph[remodelingIndexGraph.length - 2] || {
          stdYear: '2023',
          stdMonth: '09',
          index: 98.7,
        };
        let x = Math.round((a.index - b.index) * 10) / 10;
        return (
          <div className='font-medium f-size-14 line-h-16 padding-x-16'>
            <span className='f-size-16 line-h-24 font-bold text-color-secondary'>
              {Number(Math.round(a.index * 10) / 10).toLocaleString()}
            </span>{' '}
            ({' '}
            <span
              style={{
                color: x < 0 ? 'blue' : 'red',
              }}
            >
              {x > 0 ? '+' : x < 0 ? '' : ''}
              {x}p
            </span>
            ,{' '}
            {a.stdMonth.split('-')[0] +
              '년' +
              ' ' +
              a.stdMonth.split('-')[1] +
              '월' +
              ' '}
            현재 )
          </div>
        );
      })(),
    },
    {
      idx: '5',
      name: '일별 농산물 도매가 지수',
      data: wholesaleGraph,
      type: 'wholesale',
      title: (() => {
        let a = wholesaleGraph[wholesaleGraph.length - 1] || {
          date: '20231028',
          totalQuantity: 2020,
          dailyIndex: 0,
        };
        let b = wholesaleGraph[wholesaleGraph.length - 2] || {
          date: '20231028',
          totalQuantity: 2020,
          dailyIndex: 0,
        };

        let x = Math.round((a.dailyIndex - b.dailyIndex) * 10) / 10;

        return (
          <div className='font-medium f-size-14 line-h-16 padding-x-16'>
            <span className='f-size-16 line-h-24 font-bold text-color-secondary'>
              {Number(a.dailyIndex).toLocaleString()}
            </span>{' '}
            ({' '}
            <span
              style={{
                color: x < 0 ? 'blue' : 'red',
              }}
            >
              {x > 0 ? '+' : x < 0 ? '' : ''}
              {x}p
            </span>
            , {a.date.slice(0, 4)}년 {a.date.slice(5, 7)}월{' '}
            {a.date.slice(8, 10)}일 현재 )
          </div>
        );
      })(),
    },
    {
      idx: '6',
      name: '일별 농산물 공판가 지수',
      data: jointMarketGraph,
      type: 'jointMarket',
      title: (() => {
        let a = jointMarketGraph[jointMarketGraph.length - 1] || {
          date: '20230802',
          dailyIndex: 3094,
          totalQuantity: 2020,
        };
        let b = jointMarketGraph[jointMarketGraph.length - 2] || {
          date: '20230802',
          dailyIndex: 3094,
          totalQuantity: 2020,
        };

        let x = Math.round((a.dailyIndex - b.dailyIndex) * 10) / 10;

        return (
          <div className='font-medium f-size-14 line-h-16 padding-x-16'>
            <span className='f-size-16 line-h-24 font-bold text-color-secondary'>
              {Number(a.dailyIndex).toLocaleString()}
            </span>
            ({' '}
            <span
              style={{
                color: x < 0 ? 'blue' : 'red',
              }}
            >
              {x > 0 ? '+' : x < 0 ? '' : ''}
              {x}p
            </span>
            , {a.date.slice(0, 4)}년 {a.date.slice(5, 7)}월{' '}
            {a.date.slice(8, 10)}일 현재 )
          </div>
        );
      })(),
    },
  ];
  return (
    <div className='flex-column bg-FFFFFF padding-b-32 browser-padding-t-48 w-100per center-y flex-center tablet-padding-x-40  browser-padding-b-80'>
      <div className='browser-max-w-1232 w-100per flex-column '>
        <div className='padding-x-16 flex w-100per '>
          <div className='font-bold browser-f-size-20 f-size-18 line-h-26 color-text-primary '>
            트랜스파머 지수
          </div>
        </div>
        <div className='browser-grid tablet-grid '>
          {dummy.map((item: any, i: number) => {
            if (item.type === 'landPrice') {
              for (let i = 0; i < item.data.length; i++) {
                item.data[i].number = Math.round(item.data[i].price * 0.3);
              }
            }
            return (
              <div
                className='flex-column browser-w-100per gap-8 tablet-w-350'
                key={item.idx}
              >
                <div className='flex-row space-between padding-16-16-0'>
                  <div className='font-bold f-size-16 line-h-24 color-text-primary'>
                    {item.name}
                  </div>
                  {item.type === 'landPrice' && (
                    <div
                      className='flex-row center-y gap-5 pointer'
                      onClick={() => {
                        setPyeong(!pyeong);
                      }}
                    ></div>
                  )}
                </div>
                <div>{item.title}</div>

                <div className='flex-row padding-x-16 space-between center-y'>
                  <div className='flex-column'>
                    <div className='font-medium f-size-16 line-h-24 color-text-secondary'>
                      전국
                    </div>
                  </div>
                  <div className='font-medium f-size-12 line-h-14 color-text-secondary'></div>
                </div>

                <div className='padding-x-16'>
                  {/* <ComposedChart
                    className="flex-medium f-size-12 center-x"
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    width={
                      isMobile(window.innerWidth)
                        ? window.innerWidth - 32
                        : 360 - 16
                    }
                    height={181}
                    data={(() => {
                      let arr =
                        pensionType === "backAndForthThinning"
                          ? [
                              ...currentPension[
                                pensionType as "fixedAmountForLife"
                              ]?.investInfoBefore.BEP,
                              currentPension[
                                pensionType as "fixedAmountForLife"
                              ]?.investInfoAfter.BEP,
                            ]
                          : currentPension[pensionType as "fixedAmountForLife"]
                              ?.investInfo.BEP;
                      if (Array.isArray(arr) && arr.length > 0) {
                        arr[0] = {
                          ...arr[0],
                          totalInvest:
                            Math.round(data.tmpAuctionPrice * 10) / 10,
                        };
                      }
                      return arr;
                    })()}
                  >
                    <XAxis
                      dataKey="year"
                      scale="point"
                      padding={{ left: 20 }}
                    />
                    <YAxis />
                    <Tooltip
                      content={({ active, payload, label }) => {
                        if (active && payload && payload.length) {
                          return (
                            <div className="padding-8 border-1-171E26 flex-column gap-8 bg-FFFFFF">
                              <div className="font-medium f-size-11 line-h-12">{`${label}년차`}</div>
                              {payload[1] ? (
                                <div className="flex-column gap-8">
                                  <div
                                    className="font-medium f-size-11 line-h-12"
                                    style={{ color: payload[0].color }}
                                  >{`투자원금 : ${transNum(
                                    Number(payload[0].value)
                                  )}원`}</div>
                                  <div
                                    className="font-medium f-size-11 line-h-12"
                                    style={{ color: payload[1].color }}
                                  >{`수익 : ${transNum(
                                    Number(payload[1].value)
                                  )}원`}</div>
                                </div>
                              ) : (
                                <div
                                  className="font-medium f-size-11 line-h-12"
                                  style={{ color: payload[0].color }}
                                >{`수익 : ${transNum(
                                  Number(payload[0].value)
                                )}원`}</div>
                              )}
                            </div>
                          );
                        }
                      }}
                    />
                    <ReferenceLine
                      y={Math.round(data.tmpAuctionPrice * 10) / 10}
                      label="BEP"
                      stroke="red"
                    />
                    <Bar dataKey="totalInvest" barSize={20} fill="#869CB6" />
                    <Line
                      type="monotone"
                      dataKey="investIncome"
                      stroke="#09CE7D"
                    />
                  </ComposedChart> */}
                  <ResponsiveContainer width='100%' height={181}>
                    <ComposedChart
                      data={item.data}
                      className='flex-medium f-size-11 center-x'
                      // margin={{
                      //   top: 5,
                      //   right: 30,
                      //   left: 20,
                      //   bottom: 5,
                      // }}
                    >
                      <XAxis
                        dataKey={
                          item.type === 'jointMarket'
                            ? 'date'
                            : item.type === 'wholesale'
                            ? 'date'
                            : item.type === 'landPrice'
                            ? 'stdMonth'
                            : 'stdMonth'
                        }
                      />
                      <YAxis yAxisId='left' />
                      <YAxis
                        hide
                        domain={
                          item.type === 'landPrice'
                            ? [0, 50000]
                            : item.type === 'housePrice'
                            ? [0, 20000]
                            : item.type === 'new'
                            ? [0, 10000]
                            : item.type === 'remodel'
                            ? [0, 5000]
                            : item.type === 'wholesale'
                            ? [0, 50000000]
                            : item.type === 'jointMarket'
                            ? [0, 5000000]
                            : [0, 10000]
                        }
                        yAxisId='right'
                        orientation='right'
                        tickFormatter={(value) =>
                          new Intl.NumberFormat('en-US').format(value)
                        }
                      />
                      <Tooltip content={<CustomTooltip type={item.type} />} />
                      <Bar
                        dataKey={
                          item.type === 'landPrice'
                            ? 'transactionNumber'
                            : item.type === 'landPrice' && !pyeong
                            ? 'amount'
                            : item.type === 'wholesale'
                            ? 'totalQuantity'
                            : item.type === 'jointMarket'
                            ? 'totalQuantity'
                            : item.type === 'housePrice'
                            ? 'transactionNumber'
                            : 'amount'
                        }
                        barSize={20}
                        fill='#869CB6'
                        yAxisId='right'
                        name='거래량'
                      />
                      <Line
                        dot={{ strokeWidth: 2, r: 2 }}
                        dataKey={
                          item.type === 'jointMarket'
                            ? 'dailyIndex'
                            : item.type === 'wholesale'
                            ? 'dailyIndex'
                            : 'index'
                        }
                        name='지수'
                        yAxisId='left'
                        stroke='#09CE7D'
                        strokeWidth={2}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Main4;
